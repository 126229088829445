/*
 * Name:        Ave - Shop styles
 * Written by: 	LiquidThemes
 */
/*----------------------------------------
 [ TABLE OF CONTENTS ]

	1. WOOCOMMERCE
		|- 1.1. Shop Product
		|- 1.2. Shop Single Page
		|- 1.3. Shop Cart Page
		|- 1.4. Shop Checkout Page
		|- 1.3. Shop Extra

	2. WOOCOMMERCE RESPONSIVE
		|- 2.1. Shop Product
		|- 2.2. Shop Single Page
		|- 2.3. Shop Cart Page
		|- 2.4. Shop Checkout Page
		|- 2.5. Shop Extra

-----------------------------------------*/
/* 1.1. Shop Product */
.ld-sp .price,
.ld-sp h3 {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.6875em;
  font-weight: normal;
}
.ld-sp .price {
  color: #191919;
  letter-spacing: 0;
}
.ld-sp .price del {
  opacity: 1;
}
.ld-sp .price ins {
  font-weight: inherit;
  text-decoration: none;
}
.ld-sp .yith-wcwl-add-to-wishlist {
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 70px;
  right: 17px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
  -webkit-transition-property: opacity, visibility, -webkit-transform;
  transition-property: opacity, visibility, -webkit-transform;
  transition-property: transform, opacity, visibility;
  transition-property: transform, opacity, visibility, -webkit-transform;
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ld-sp .yith-wcwl-add-to-wishlist > div a {
  border: none;
  border-radius: 50em !important;
  background-color: #fff;
  font-size: 12px;
}
.ld-sp .yith-wcwl-add-to-wishlist > div a:hover {
  color: var(--color-primary);
}
.ld-sp .yith-wcwl-add-to-wishlist > div i {
  margin: 0;
  font-size: 17px;
}
.ld-sp .yith-wcwl-add-to-wishlist > div .feedback {
  visibility: hidden;
  opacity: 0;
}
.ld-sp .yith-wcwl-add-to-wishlist > div.show {
  width: 39px;
  height: 39px;
}
.ld-sp .yith-wcwl-add-to-wishlist > div.yith-wcwl-add-button i.fa-heart-o + .fa-heart {
  display: none;
}
.ld-sp .yith-wcwl-add-to-wishlist > div.yith-wcwl-wishlistaddedbrowse a, .ld-sp .yith-wcwl-add-to-wishlist > div.yith-wcwl-wishlistexistsbrowse a {
  color: var(--color-primary);
}
.ld-sp .yith-wcwl-add-to-wishlist .ajax-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ld-sp:hover .yith-wcwl-add-to-wishlist {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
}

.ld-sp a.button,
.ld-sp input.button,
.ld-sp button.button,
.ld-sp #respond input#submit {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 1.15em 2.1em;
  position: relative;
  border-radius: 0;
  background-color: var(--color-primary);
  font-size: 13px;
  line-height: 1.5em;
  color: #fff;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05), 0 0 0 rgba(0, 0, 0, 0.05);
}
.ld-sp a.button > i,
.ld-sp input.button > i,
.ld-sp button.button > i,
.ld-sp #respond input#submit > i {
  margin-right: 0.75em;
}
.ld-sp a.button > span + i,
.ld-sp input.button > span + i,
.ld-sp button.button > span + i,
.ld-sp #respond input#submit > span + i {
  margin-right: 0;
  margin-left: 0.75em;
}
.ld-sp a.button.loading,
.ld-sp input.button.loading,
.ld-sp button.button.loading,
.ld-sp #respond input#submit.loading {
  padding-right: 2.1em;
}
.ld-sp a.button.loading:after,
.ld-sp input.button.loading:after,
.ld-sp button.button.loading:after,
.ld-sp #respond input#submit.loading:after {
  position: relative;
  top: auto;
  right: auto;
  margin-left: 0.75em;
}
.ld-sp a.button.added:after,
.ld-sp input.button.added:after,
.ld-sp button.button.added:after,
.ld-sp #respond input#submit.added:after {
  margin-left: 0.75em;
}
.ld-sp a.button.disabled, .ld-sp a.button:disabled, .ld-sp a.button:disabled[disabled],
.ld-sp input.button.disabled,
.ld-sp input.button:disabled,
.ld-sp input.button:disabled[disabled],
.ld-sp button.button.disabled,
.ld-sp button.button:disabled,
.ld-sp button.button:disabled[disabled],
.ld-sp #respond input#submit.disabled,
.ld-sp #respond input#submit:disabled,
.ld-sp #respond input#submit:disabled[disabled] {
  padding: 1.15em 2.1em;
}
.ld-sp a.button:hover, .ld-sp a.button:focus,
.ld-sp input.button:hover,
.ld-sp input.button:focus,
.ld-sp button.button:hover,
.ld-sp button.button:focus,
.ld-sp #respond input#submit:hover,
.ld-sp #respond input#submit:focus {
  background-color: #000;
  color: #fff;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}
.ld-sp a.added_to_cart {
  display: block;
}

.ld-sp {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.6875em;
}
.ld-sp figure {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}
.ld-sp figure img {
  width: 100%;
  margin: 0 !important;
}
.ld-sp:hover .ld-sp-img-hover,
.ld-sp:hover .ld-sp-add-to-cart,
.ld-sp:hover .add_to_cart_button {
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
  opacity: 1;
}

.ld-sp-img-hover {
  opacity: 0;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transition: opacity 0.85s, -webkit-transform 0.85s;
  transition: opacity 0.85s, -webkit-transform 0.85s;
  transition: opacity 0.85s, transform 0.85s;
  transition: opacity 0.85s, transform 0.85s, -webkit-transform 0.85s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ld-sp-info h3 {
  margin: 0;
  font-size: inherit;
}
.ld-sp-info h3 a {
  color: #000;
}

.ld-sp-price {
  color: #191919;
  margin: 0;
}

.ld-sp .ld-sp-add-to-cart,
.ld-sp .add_to_cart_button {
  display: block;
  padding: 8px 15px;
  position: absolute;
  left: 17px;
  right: 17px;
  bottom: 15px;
  background-color: rgba(0, 0, 0, 0.9);
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.185em;
  line-height: 1.5em;
  font-weight: 700;
  text-align: center;
  color: #fff;
  white-space: normal;
  -webkit-transform: translateY(15%);
          transform: translateY(15%);
  -webkit-transition-property: background, opacity, -webkit-transform, -webkit-transform-origin;
  transition-property: background, opacity, -webkit-transform, -webkit-transform-origin;
  transition-property: transform, background, opacity, transform-origin;
  transition-property: transform, background, opacity, transform-origin, -webkit-transform, -webkit-transform-origin;
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}
.ld-sp .ld-sp-add-to-cart:after,
.ld-sp .add_to_cart_button:after {
  display: inline-block;
}
.ld-sp .ld-sp-add-to-cart.loading,
.ld-sp .add_to_cart_button.loading {
  opacity: 1 !important;
}
.ld-sp .ld-sp-add-to-cart.loading, .ld-sp .ld-sp-add-to-cart.added,
.ld-sp .add_to_cart_button.loading,
.ld-sp .add_to_cart_button.added {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
.ld-sp .ld-sp-add-to-cart:hover,
.ld-sp .add_to_cart_button:hover {
  background-color: var(--color-primary);
  color: #fff;
}
.ld-sp .added_to_cart {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #000;
  font-size: 12px;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.185em;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ld-sp .added_to_cart:hover {
  background-color: var(--color-primary);
}

.ld-sp-soldout,
.ld-sp-sale {
  padding: 5px 12px;
  margin: 0;
  position: absolute;
  top: 18px;
  left: 18px;
  right: auto;
  border-radius: 2px;
  background-color: #fff;
  font-size: 12px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.185em;
  font-weight: 700;
  color: #000;
}
.ld-sp-soldout:only-child,
.ld-sp-sale:only-child {
  position: relative;
  top: auto;
  left: auto;
  margin-bottom: 1px;
  background-color: #000;
  color: #fff;
}

.ld-sp-soldout {
  background-color: #E82D2D;
  color: #fff;
}

.ld-bsp {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  margin-bottom: 20px;
}
.ld-bsp img {
  border-radius: 2px;
}

.ld-bsp-info {
  font-size: 1em;
  line-height: 1.8em;
}
.ld-bsp-info h3 {
  font-size: 1em;
  line-height: 1.5em;
  margin: 0.5em 0 0;
}
.ld-bsp-info h3 a {
  color: #000;
}
.ld-bsp-img + .ld-bsp-info {
  margin-left: 17px;
}

.ld-bsp-carousel .ld-bsp-img a {
  display: inline-block;
  height: 75px;
  overflow: hidden;
}
.ld-bsp-carousel .ld-bsp-img img {
  width: 75px;
}
.ld-bsp-carousel .flickity-button {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #F2F2F2;
}
.ld-bsp-carousel .flickity-button:hover {
  background-color: #000;
  color: #fff;
}

/* 1.2. Shop Single Page */
.woocommerce div.product:before, .woocommerce div.product:after {
  content: '';
  display: table;
}
.woocommerce div.product:after {
  clear: both;
}
.woocommerce div.product form.cart {
  margin-bottom: 10px;
}
.woocommerce div.product .ld-product-summary {
  margin-bottom: 0;
}
.woocommerce div.product .ld-product-summary .product-rating {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 1.5em;
}
.woocommerce div.product .ld-product-summary .review-link {
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
  font-size: 0.7333333333em;
  line-height: 1.1818181818em;
  margin-left: 2em;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}
.woocommerce div.product .ld-product-summary .product-meta {
  font-size: 13px;
}
.woocommerce div.product .ld-product-summary .product-meta > span {
  display: block;
  padding-bottom: 0.75em;
  margin-bottom: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #000;
}
.woocommerce div.product .ld-product-summary .product-meta > span span {
  color: #BCBCBC;
}
.woocommerce div.product .ld-product-summary + .product-meta {
  float: right;
  width: 48%;
  clear: none;
}

.ld-product-zoom {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 45px;
  height: 45px;
  background: #fff;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  z-index: 9;
  font-size: 20px;
  color: #000;
  border-radius: 50em;
}
.ld-product-zoom:hover {
  background-color: #000;
  color: #fff;
}

.woocommerce .woocommerce-product-details__short-description {
  margin-bottom: 15px;
}
.woocommerce .woocommerce-product-details__short-description p {
  margin-bottom: 0.75em;
}
.woocommerce .woocommerce-product-details__short-description p:last-child {
  margin-bottom: 0;
}
.woocommerce .woocommerce-product-details__short-description ul {
  margin: 0;
  padding: 0;
  list-style-position: inside;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .product_title,
.woocommerce div.product .woocommerce-product-rating {
  line-height: 1.5em;
  color: #000;
}
.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .woocommerce-product-rating {
  font-size: 30px;
  margin-bottom: 0.6666666667em;
}
.woocommerce div.product .product_title {
  margin-bottom: 0.3703703704em;
  font-size: 27px;
  font-weight: 700;
}
.woocommerce div.product span.price,
.woocommerce div.product p.price {
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.05em;
}
.woocommerce div.product span.price ins,
.woocommerce div.product p.price ins {
  text-decoration: none;
}
.woocommerce div.product span.price del,
.woocommerce div.product p.price del {
  opacity: 0.5;
}
.woocommerce div.product .woocommerce-product-rating {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-left: 20px;
  -webkit-box-align: center;
          align-items: center;
  vertical-align: bottom;
}
.woocommerce div.product .star-rating {
  height: 1em;
  position: relative;
  display: inline-block;
  font-size: 15px;
  width: 5.65em;
  margin: 0;
}
.woocommerce div.product .star-rating span {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 1em;
  padding-top: 1.1em;
  overflow: hidden;
}
.woocommerce div.product .star-rating:before,
.woocommerce div.product .star-rating span::before {
  content: "\f005\f005\f005\f005\f005";
  width: 100%;
  height: 1em;
  position: absolute;
  top: 0;
  left: 0;
  font-family: "FontAwesome";
  font-size: 1em;
  line-height: 1.1em;
  color: #d2d2d2;
  letter-spacing: 0.25em;
}
.woocommerce div.product .star-rating span::before {
  color: var(--color-primary);
}
.woocommerce div.product .star-rating:after {
  display: none;
}
.woocommerce .woocommerce-review-link {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #090909;
  margin-left: 10px;
}
.woocommerce .woocommerce-review-link:hover {
  color: #000;
}

.woocommerce div.product form.cart table.variations tr {
  display: block;
  margin-bottom: 10px;
  border: none;
}
.woocommerce div.product form.cart table.variations td {
  display: inline-block;
}
.woocommerce div.product form.cart table.variations td.label {
  width: 100%;
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #000;
}
.woocommerce div.product form.cart table.variations td.value {
  min-width: 50%;
  position: relative;
}
.woocommerce div.product form.cart table.variations .lqd-select-wrap {
  display: block;
  position: relative;
}
.woocommerce div.product form.cart table.variations .lqd-select-wrap .lqd-select-ext {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.woocommerce div.product form.cart table.variations .lqd-select-wrap .lqd-select-ext:after {
  content: '\f107';
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-family: fontAwesome;
  font-size: 18px;
  text-indent: 0;
}
.woocommerce div.product form.cart table.variations #pa_color ~ .lqd-select-ext,
.woocommerce div.product form.cart table.variations #pa_size ~ .lqd-select-ext {
  display: none;
}
.woocommerce div.product form.cart table.variations select {
  height: 50px;
  width: 100%;
  padding: 0 25px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #EBEBEB;
  border-radius: 0;
  cursor: pointer;
  position: relative;
}
.woocommerce div.product form.cart table.variations select:focus {
  outline: none;
}
.woocommerce div.product form.cart .reset_variations {
  display: block;
  margin-top: 1em;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.woocommerce div.product form.cart.variations_form + .yith-wcwl-add-to-wishlist {
  margin-left: 0;
}

.woocommerce div.product .single_variation_wrap {
  margin-bottom: 30px;
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.woocommerce div.product .single_variation_wrap:only-child {
  margin-bottom: 0;
}
.woocommerce div.product .single_variation_wrap .woocommerce-variation-availability {
  margin-bottom: 1em;
}
.woocommerce div.product .single_variation_wrap .ld-product-summary-foot .single_add_to_cart_button:only-child {
  margin-bottom: 0;
}

.woocommerce div.product form.cart .group_table {
  width: 100%;
  margin-bottom: 2em;
}
.woocommerce div.product form.cart .group_table td {
  padding: 10px 15px !important;
}
.woocommerce div.product form.cart .group_table label {
  margin: 0;
}
.woocommerce div.product form.cart .group_table label a,
.woocommerce div.product form.cart .group_table .woocommerce-grouped-product-list-item__label,
.woocommerce div.product form.cart .group_table .woocommerce-Price-amount {
  color: #000;
}
.woocommerce div.product form.cart .group_table label a {
  font-weight: 600;
}
.woocommerce div.product form.cart .group_table label a:hover {
  color: var(--color-primary);
}
.woocommerce div.product form.cart .group_table .ld-product-quantity {
  margin-bottom: 0 !important;
}

.woocommerce form.cart div.quantity {
  float: none !important;
}
.woocommerce .ui-spinner {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 105px;
  border: none;
  background: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}
.woocommerce .ui-spinner .ui-spinner-input {
  width: 35px !important;
  margin: 0 2px;
  padding: 0 2px;
  text-align: center;
  background: none;
}
.woocommerce .ui-spinner .ui-spinner-input:focus {
  outline: none;
}
.woocommerce .ui-spinner .ui-button {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 32px;
  height: 32px;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  background: none;
  border: 1px solid #000;
  border-radius: 50em;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  color: #000;
  cursor: pointer;
}
.woocommerce .ui-spinner .ui-button:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.woocommerce .ui-spinner .ui-spinner-up .ui-button-icon,
.woocommerce .ui-spinner .ui-spinner-down .ui-button-icon {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  background: none;
}
.woocommerce .ui-spinner .ui-spinner-up .ui-button-icon:before,
.woocommerce .ui-spinner .ui-spinner-down .ui-button-icon:before {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 18px;
  font-family: liquid-icon;
  line-height: 1;
  text-indent: 0;
}
.woocommerce .ui-spinner .ui-spinner-down {
  -webkit-box-ordinal-group: 0;
          order: -1;
}
.woocommerce .ui-spinner .ui-spinner-down .ui-button-icon:before {
  content: '\f1fc';
}
.woocommerce .ui-spinner .ui-spinner-up .ui-button-icon:before {
  content: '\f102';
}

.select_box_colorpicker .select_option {
  border: none;
  margin-right: 11px;
}
.select_box_colorpicker .select_option span {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50em;
  position: relative;
}
.select_box_colorpicker .select_option span:before {
  content: '\f3ff';
  display: -webkit-inline-box;
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-family: liquid-icon;
  font-size: 24px;
  color: #fff;
  opacity: 0;
}
.select_box_colorpicker .select_option.selected span:before {
  opacity: 1;
}

.select_box_label .select_option_label {
  border: none;
  margin-right: 5px;
}
.select_box_label .select_option_label span {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 50px;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1em;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  color: #000;
}
.select_box_label .select_option_label:hover span {
  border-color: rgba(0, 0, 0, 0.5);
}
.select_box_label .select_option_label.selected span {
  border-color: #000;
  background: #000;
  color: #fff;
}

.ld-product-summary .out-of-stock {
  display: inline-block;
  padding: 10px 16px;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  color: #D0021B !important;
  background-color: rgba(208, 2, 27, 0.05) !important;
}
.ld-product-summary .variations_form .out-of-stock {
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
}

.ld-product-summary-foot {
  flex-wrap: wrap;
}
.ld-product-summary-foot :first-child {
  margin-left: 0 !important;
}
.ld-product-summary-foot button[type=submit] {
  height: 50px;
  padding: 0 !important;
  margin-bottom: 15px;
}
.ld-product-summary-foot button[type=submit] > span {
  padding-left: 33px;
  padding-right: 33px;
}
.ld-product-summary-foot .social-icon {
  margin-left: 22px;
  margin-bottom: 15px;
}
.ld-product-summary-foot .social-icon li a:hover {
  color: var(--color-primary);
}

.yith-wcwl-add-to-wishlist {
  margin: 0 0 15px 22px;
}
.yith-wcwl-add-to-wishlist > div {
  position: relative;
}
.yith-wcwl-add-to-wishlist > div a,
.yith-wcwl-add-to-wishlist > div i {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.yith-wcwl-add-to-wishlist > div a {
  border: 1px solid #E3E3E3;
  border-radius: 0 !important;
  font-size: 16px;
  color: #000;
  text-indent: 99999px;
  overflow: hidden;
}
.yith-wcwl-add-to-wishlist > div a:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}
.yith-wcwl-add-to-wishlist > div i {
  text-indent: 0;
}
.yith-wcwl-add-to-wishlist > div .feedback {
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 7px;
  position: absolute;
  bottom: 100%;
  left: 0;
  border-radius: 3px;
  background-color: #fff;
  color: #000;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
  -webkit-transition: opacity 0.1s, visibility 0.1s;
  transition: opacity 0.1s, visibility 0.1s;
}
.yith-wcwl-add-to-wishlist > div.show {
  display: -webkit-box !important;
  display: flex !important;
  width: 53px;
  height: 50px;
}
.yith-wcwl-add-to-wishlist > div:not(.yith-wcwl-add-button) i {
  margin-right: 10px;
}
.yith-wcwl-add-to-wishlist > div:not(.yith-wcwl-add-button) i:before {
  content: '\f004';
}
.yith-wcwl-add-to-wishlist > div:hover .feedback {
  opacity: 1;
  visibility: visible;
}

.woocommerce .product_meta {
  margin-bottom: 2em;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0.08em;
  font-weight: 700;
  color: #040404;
}
.woocommerce .product_meta > span {
  display: block;
  padding: 1em 0;
  border-bottom: 1px solid #eee;
}
.woocommerce .product_meta > span span {
  color: #BCBCBC;
}

.woocommerce div.product .woocommerce-tabs {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 70px;
}
.woocommerce div.product .woocommerce-tabs .tabs {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
}
.woocommerce div.product .woocommerce-tabs .tabs-nav {
  margin: 0;
  padding: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column nowrap;
}
.woocommerce div.product .woocommerce-tabs .tabs-nav li {
  border-top: 1px solid #F2F2F2;
  -webkit-box-pack: start;
          justify-content: flex-start;
  font-size: 15px;
  font-weight: 500;
}
.woocommerce div.product .woocommerce-tabs .tabs-nav li:last-child {
  border-bottom: 1px solid #F2F2F2;
}
.woocommerce div.product .woocommerce-tabs .tabs-nav li a {
  width: 100%;
  padding: 14px 0;
  -webkit-box-pack: inherit;
          justify-content: inherit;
  color: #454545;
}
.woocommerce div.product .woocommerce-tabs .tabs-nav li a:after {
  content: '\f105';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
  font: normal normal 1em/1 fontAwesome;
  -webkit-transform: translate(-5px, -50%);
          transform: translate(-5px, -50%);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.woocommerce div.product .woocommerce-tabs .tabs-nav li a:hover, .woocommerce div.product .woocommerce-tabs .tabs-nav li.active a {
  color: var(--color-primary);
}
.woocommerce div.product .woocommerce-tabs .tabs-nav li a:hover:after, .woocommerce div.product .woocommerce-tabs .tabs-nav li.active a:after {
  opacity: 1;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.woocommerce div.product .woocommerce-tabs .woocommerce-Tabs-panel > h2,
.woocommerce div.product .woocommerce-tabs .woocommerce-Tabs-panel .comment-reply-title,
.woocommerce div.product .woocommerce-tabs .woocommerce-Tabs-panel .woocommerce-Reviews-title {
  margin: 0 0 1em;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: none;
  letter-spacing: 0;
  color: #030303;
}
.woocommerce div.product .woocommerce-tabs .woocommerce-Tabs-panel .woocommerce-Reviews-title {
  margin-bottom: 1.5em;
}

.woocommerce table.shop_attributes {
  border: none;
  font-size: 15px;
  color: #454545;
}
.woocommerce table.shop_attributes tr,
.woocommerce table.shop_attributes th,
.woocommerce table.shop_attributes td {
  border: none;
}
.woocommerce table.shop_attributes th,
.woocommerce table.shop_attributes td {
  padding: 7px 20px;
  font-weight: 400;
}
.woocommerce table.shop_attributes th {
  background-color: #F8F8F8;
}
.woocommerce table.shop_attributes td {
  background-color: #FDFDFD !important;
}

.woocommerce #reviews .comment-reply-title {
  display: block;
  text-align: inherit;
}
.woocommerce #reviews #comments ol.commentlist {
  padding: 0;
  list-style: none;
}
.woocommerce #reviews #comments ol.commentlist li {
  padding: 20px 35px;
  border: 1px solid #EAEAEA;
  font-size: 15px;
  line-height: 24px;
  color: #454545;
}
.woocommerce #reviews #comments ol.commentlist li img.avatar {
  float: left;
  width: 45px;
  padding: 0;
  margin-right: 1em;
  border: none;
  border-radius: 50em;
  position: relative;
  top: auto;
  left: auto;
  background: none;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text {
  padding: 0;
  margin: 0;
  border: none;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating,
.woocommerce #reviews #comments ol.commentlist li .comment-text .meta {
  margin-top: 0.75em;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating {
  float: right;
}
.woocommerce #reviews #comments ol.commentlist li .comment-text .meta {
  font-size: .85em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: rgba(18, 17, 18, 0.27);
}
.woocommerce #reviews #comments ol.commentlist li .comment-text .woocommerce-review__author {
  color: #121212;
}
.woocommerce #reviews #comments ol.commentlist li .description {
  margin-top: 2.5em;
}
.woocommerce #reviews #comments ol.commentlist li .description p {
  margin: 0;
}

.woocommerce #review_form #respond {
  padding: 35px 40px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.woocommerce #review_form #respond .comment-form {
  margin-top: 20px;
}
.woocommerce #review_form #respond .comment-form > .row {
  display: -webkit-box;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: start;
          align-items: flex-start;
}
.woocommerce #review_form #respond .comment-form > .row:before, .woocommerce #review_form #respond .comment-form > .row:after {
  content: none;
}
.woocommerce #review_form #respond .comment-form p {
  width: 100%;
  margin-bottom: 40px;
  -webkit-perspective: none;
          perspective: none;
}
.woocommerce #review_form #respond .comment-form p:before {
  background-color: #cccccc;
}
.woocommerce #review_form #respond .comment-form input {
  height: 60px;
}
.woocommerce #review_form #respond .comment-form input[type=submit] {
  height: 45px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #3A3A3A;
  color: #fff;
}
.woocommerce #review_form #respond .comment-form input[type=submit]:hover {
  background-color: #000;
}
.woocommerce #review_form #respond .comment-form input[type=checkbox], .woocommerce #review_form #respond .comment-form input[type=radio] {
  width: auto;
  height: auto;
}
.woocommerce #review_form #respond .comment-form .comment-notes {
  text-align: inherit;
  margin-bottom: 20px;
}
.woocommerce #review_form #respond .comment-form .comment-form-rating {
  -webkit-box-ordinal-group: 1;
          order: 0;
}
.woocommerce #review_form #respond .comment-form .comment-form-comment {
  -webkit-box-ordinal-group: 2;
          order: 1;
}
.woocommerce #review_form #respond .comment-form .comment-form-comment .input-placeholder {
  height: auto;
}
.woocommerce #review_form #respond .comment-form .comment-form-author,
.woocommerce #review_form #respond .comment-form .comment-form-email {
  -webkit-box-ordinal-group: 3;
          order: 2;
}
.woocommerce #review_form #respond .comment-form .comment-form-cookies-consent {
  -webkit-box-ordinal-group: 4;
          order: 3;
}
.woocommerce #review_form #respond .comment-form .comment-form-cookies-consent:before, .woocommerce #review_form #respond .comment-form .comment-form-cookies-consent:after {
  content: none;
}
.woocommerce #review_form #respond .comment-form .comment-form-cookies-consent input,
.woocommerce #review_form #respond .comment-form .comment-form-cookies-consent label {
  margin-top: 0;
  margin-bottom: 0;
}
.woocommerce #review_form #respond .comment-form .form-submit {
  -webkit-box-ordinal-group: 5;
          order: 4;
}
.woocommerce #review_form #respond .comment-form .comment-form-rating {
  display: inline-block;
  padding: 16px 75px 16px 25px;
  margin-bottom: 35px;
  border: 1px solid #efefef;
}
.woocommerce #review_form #respond .comment-form .stars {
  margin: 0 !important;
  font-size: 15px;
  color: var(--color-primary);
}
.woocommerce #review_form #respond .comment-form .stars:before, .woocommerce #review_form #respond .comment-form .stars:after {
  content: none;
}
.woocommerce #review_form #respond .comment-form .stars a {
  color: inherit;
}
.woocommerce #review_form #respond .comment-form .stars .star-5:after {
  content: '0 of 5';
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 1em;
  font-size: 14px;
  font-weight: 600;
  text-indent: 0;
  line-height: 1em;
  white-space: nowrap;
  color: #000000;
  pointer-events: none;
}
.woocommerce #review_form #respond .comment-form .stars span {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.woocommerce #review_form #respond .comment-form .stars .star-1.active ~ .star-5:after {
  content: "1 of 5";
}
.woocommerce #review_form #respond .comment-form .stars .star-2.active ~ .star-5:after {
  content: "2 of 5";
}
.woocommerce #review_form #respond .comment-form .stars .star-3.active ~ .star-5:after {
  content: "3 of 5";
}
.woocommerce #review_form #respond .comment-form .stars .star-4.active ~ .star-5:after {
  content: "4 of 5";
}
.woocommerce #review_form #respond .comment-form .stars .star-5.active:after {
  content: "5 of 5";
}

.related.products {
  clear: both;
}
.related.products > h2 {
  margin-bottom: 1.5em;
}

/* 1.3. Shop Cart Page */
.woocommerce-cart #content {
  padding-top: 70px;
  padding-bottom: 70px;
}
.woocommerce-cart .titlebar + #content {
  padding-top: 40px;
}
.woocommerce-cart .cart-empty.woocommerce-info {
  display: block;
  padding: 0;
  margin-top: 1em;
  border: none;
  background: none;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  color: #000;
}
.woocommerce-cart .cart-empty.woocommerce-info:before, .woocommerce-cart .cart-empty.woocommerce-info:after {
  content: none;
}
.woocommerce-cart .cart-empty.woocommerce-info + .return-to-shop {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
}

.woocommerce #yith-wcwl-form,
.woocommerce .woocommerce-cart-form {
  margin: 2em 0;
}
.woocommerce #yith-wcwl-form table.shop_table,
.woocommerce .woocommerce-cart-form table.shop_table {
  margin: 0;
  border: none;
  border-radius: 0;
}
.woocommerce #yith-wcwl-form table.shop_table th,
.woocommerce #yith-wcwl-form table.shop_table td,
.woocommerce .woocommerce-cart-form table.shop_table th,
.woocommerce .woocommerce-cart-form table.shop_table td {
  border: none;
  padding: 25px 0;
}
.woocommerce #yith-wcwl-form table.shop_table th:first-child,
.woocommerce #yith-wcwl-form table.shop_table td:first-child,
.woocommerce .woocommerce-cart-form table.shop_table th:first-child,
.woocommerce .woocommerce-cart-form table.shop_table td:first-child {
  padding-left: 28px;
}
.woocommerce #yith-wcwl-form table.shop_table thead,
.woocommerce .woocommerce-cart-form table.shop_table thead {
  background-color: #F8F8F8;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #000;
}
.woocommerce #yith-wcwl-form table.shop_table tbody,
.woocommerce .woocommerce-cart-form table.shop_table tbody {
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 500;
  color: #000;
}
.woocommerce #yith-wcwl-form table.shop_table tbody tr:nth-child(even):not(:last-child),
.woocommerce .woocommerce-cart-form table.shop_table tbody tr:nth-child(even):not(:last-child) {
  background-color: #FBFBFB;
}
.woocommerce #yith-wcwl-form table.shop_table .product-remove a.remove,
.woocommerce .woocommerce-cart-form table.shop_table .product-remove a.remove {
  color: var(--color-primary) !important;
}
.woocommerce #yith-wcwl-form table.shop_table .product-remove a.remove:hover,
.woocommerce .woocommerce-cart-form table.shop_table .product-remove a.remove:hover {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}
.woocommerce #yith-wcwl-form table.shop_table .product-name img,
.woocommerce .woocommerce-cart-form table.shop_table .product-name img {
  width: 64px;
  margin-right: 25px;
}
.woocommerce #yith-wcwl-form table.shop_table .product-name a,
.woocommerce .woocommerce-cart-form table.shop_table .product-name a {
  color: #000;
}
.woocommerce #yith-wcwl-form table.shop_table .product-name a:hover,
.woocommerce .woocommerce-cart-form table.shop_table .product-name a:hover {
  color: var(--color-primary);
}
.woocommerce #yith-wcwl-form table.shop_table .product-name-inner,
.woocommerce .woocommerce-cart-form table.shop_table .product-name-inner {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
}
.woocommerce #yith-wcwl-form table.shop_table .product-name-inner:only-child,
.woocommerce .woocommerce-cart-form table.shop_table .product-name-inner:only-child {
  -webkit-box-flex: 1;
          flex-grow: 1;
  width: 100%;
}
.woocommerce #yith-wcwl-form table.shop_table .product-name-inner .product-info,
.woocommerce .woocommerce-cart-form table.shop_table .product-name-inner .product-info {
  -webkit-box-flex: 1;
          flex-grow: 1;
}
.woocommerce #yith-wcwl-form table.shop_table .product-name-inner .variation,
.woocommerce .woocommerce-cart-form table.shop_table .product-name-inner .variation {
  margin-top: 3px;
}
.woocommerce #yith-wcwl-form table.shop_table .ld-product-quantity,
.woocommerce .woocommerce-cart-form table.shop_table .ld-product-quantity {
  margin-bottom: 0 !important;
}
.woocommerce #yith-wcwl-form table.shop_table .ld-product-quantity .ui-spinner .ui-button,
.woocommerce .woocommerce-cart-form table.shop_table .ld-product-quantity .ui-spinner .ui-button {
  border-color: #ADADAD;
}
.woocommerce #yith-wcwl-form table.shop_table .ld-product-quantity .ui-spinner .ui-button:before,
.woocommerce .woocommerce-cart-form table.shop_table .ld-product-quantity .ui-spinner .ui-button:before {
  color: #5E5E5E;
}
.woocommerce #yith-wcwl-form table.shop_table .ld-product-quantity .ui-spinner .ui-button:hover,
.woocommerce .woocommerce-cart-form table.shop_table .ld-product-quantity .ui-spinner .ui-button:hover {
  border-color: var(--color-primary);
  background-color: transparent;
  color: var(--color-primary);
}
.woocommerce #yith-wcwl-form table.shop_table .ld-product-quantity .ui-spinner .ui-button:hover:before,
.woocommerce .woocommerce-cart-form table.shop_table .ld-product-quantity .ui-spinner .ui-button:hover:before {
  color: var(--color-primary);
}
.woocommerce #yith-wcwl-form table.shop_table .woo-actions-inner,
.woocommerce .woocommerce-cart-form table.shop_table .woo-actions-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
          align-items: center;
}
.woocommerce #yith-wcwl-form table.shop_table .woo-actions-inner .button,
.woocommerce .woocommerce-cart-form table.shop_table .woo-actions-inner .button {
  border: 1px solid transparent;
}
.woocommerce #yith-wcwl-form table.shop_table .woo-actions-inner .button + .button,
.woocommerce .woocommerce-cart-form table.shop_table .woo-actions-inner .button + .button {
  margin-left: 24px;
}
.woocommerce #yith-wcwl-form table.shop_table .woo-actions-inner .continue_shopping,
.woocommerce .woocommerce-cart-form table.shop_table .woo-actions-inner .continue_shopping {
  background: none;
  border: 1px solid #E6E6E6;
  color: #000;
}
.woocommerce #yith-wcwl-form table.shop_table .woo-actions-inner .continue_shopping:hover,
.woocommerce .woocommerce-cart-form table.shop_table .woo-actions-inner .continue_shopping:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}
.woocommerce #yith-wcwl-form table.shop_table .woo-actions-inner .update_cart,
.woocommerce .woocommerce-cart-form table.shop_table .woo-actions-inner .update_cart {
  background-color: #000;
  color: #fff;
}
.woocommerce #yith-wcwl-form table.shop_table .woo-actions-inner .update_cart:hover,
.woocommerce .woocommerce-cart-form table.shop_table .woo-actions-inner .update_cart:hover {
  background-color: var(--color-primary);
}
.woocommerce #yith-wcwl-form .cart-coupon,
.woocommerce .woocommerce-cart-form .cart-coupon {
  padding-left: 0 !important;
}
.woocommerce #yith-wcwl-form .cart-coupon-inner,
.woocommerce .woocommerce-cart-form .cart-coupon-inner {
  display: -webkit-box;
  display: flex;
  position: relative;
  border: 1px dashed #D4D4D4;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 25px;
}
.woocommerce #yith-wcwl-form .cart-coupon-inner > i,
.woocommerce .woocommerce-cart-form .cart-coupon-inner > i {
  font-size: 1.5em;
  color: var(--color-primary);
}
.woocommerce #yith-wcwl-form .cart-coupon-inner input,
.woocommerce .woocommerce-cart-form .cart-coupon-inner input {
  padding: 13px 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1em;
  border: none;
  -webkit-box-flex: 1;
          flex: 1 auto;
  color: #5E5E5E;
}
.woocommerce #yith-wcwl-form .cart-coupon-inner input:focus,
.woocommerce .woocommerce-cart-form .cart-coupon-inner input:focus {
  outline: none;
}
.woocommerce #yith-wcwl-form .cart-coupon-inner button,
.woocommerce .woocommerce-cart-form .cart-coupon-inner button {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 64px;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 20px;
  color: var(--color-primary);
}
.woocommerce #yith-wcwl-form .cart-coupon-inner button i,
.woocommerce .woocommerce-cart-form .cart-coupon-inner button i {
  margin: 0;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.woocommerce #yith-wcwl-form .cart-coupon-inner button:hover,
.woocommerce .woocommerce-cart-form .cart-coupon-inner button:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}
.woocommerce #yith-wcwl-form .cart-coupon-inner button:hover i,
.woocommerce .woocommerce-cart-form .cart-coupon-inner button:hover i {
  -webkit-transform: translateX(3px);
          transform: translateX(3px);
}
.woocommerce .woocommerce-cart-form .shipping-calculator-button {
  white-space: nowrap;
}
.woocommerce .cart_totals h3 {
  font-size: 23px;
  font-weight: 700;
  line-height: 1.5em;
  margin-bottom: 1.75em;
}
.woocommerce .cart_totals table.shop_table th,
.woocommerce .cart_totals table.shop_table td {
  font-size: 15px;
  font-weight: 400;
  color: #454545;
}
.woocommerce .cart_totals table.shop_table th {
  padding: 15px !important;
  background-color: #F8F8F8;
}
.woocommerce .cart_totals table.shop_table td {
  padding: 15px 25px;
  background-color: #FDFDFD;
}
.woocommerce .cart_totals .woo-checkout-btn a.button.alt {
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
}
.woocommerce .cart_totals .woo-checkout-btn a.button.alt i {
  font-size: 1.25em;
  font-weight: 700;
}
.woocommerce .cart_totals .woo-checkout-btn a.button.alt i:before {
  content: "\e988";
  font-family: 'liquid-icon' !important;
}
.woocommerce .cart-collaterals .cart_totals {
  float: right;
  width: 48%;
}
.woocommerce .product-add-to-cart a.button,
.woocommerce .product-add-to-cart a.button.alt,
.woocommerce .woo-checkout-btn a.button,
.woocommerce .woo-checkout-btn a.button.alt {
  display: -webkit-box;
  display: flex;
  width: 100%;
  padding: 1.6666666667em;
  margin-top: 2.0833333333em;
  font-size: 12px;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-color: var(--color-primary);
  color: #fff;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.woocommerce .product-add-to-cart a.button:hover,
.woocommerce .product-add-to-cart a.button.alt:hover,
.woocommerce .woo-checkout-btn a.button:hover,
.woocommerce .woo-checkout-btn a.button.alt:hover {
  background-color: #000;
}
.woocommerce form.woocommerce-shipping-calculator > p {
  margin-bottom: 0;
}
.woocommerce form.woocommerce-shipping-calculator p {
  padding: 3px;
}
.woocommerce form.woocommerce-shipping-calculator .input-text {
  padding: 5px 15px;
}
.woocommerce form.woocommerce-shipping-calculator button {
  width: 100%;
  -webkit-box-pack: center;
          justify-content: center;
}

.woocommerce #yith-wcwl-form table.shop_table .product-name {
  padding-left: 15px;
}
.woocommerce #yith-wcwl-form table.shop_table .product-remove {
  padding-right: 25px;
}
.woocommerce #yith-wcwl-form table.shop_table .product-add-to-cart {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: end;
          justify-content: flex-end;
  padding-right: 25px;
  text-align: right;
}
.woocommerce #yith-wcwl-form table.shop_table .product-add-to-cart .yith-wcwl-add-to-wishlist,
.woocommerce #yith-wcwl-form table.shop_table .product-add-to-cart a.button {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
}
.woocommerce #yith-wcwl-form table.shop_table .yith-wcwl-add-to-wishlist > div a {
  text-indent: -99999px;
}
.woocommerce #yith-wcwl-form table.shop_table .yith-wcwl-add-to-wishlist > div .feedback {
  left: auto;
  right: 0;
}
.woocommerce #yith-wcwl-form table.shop_table a.remove {
  display: -webkit-box;
  display: flex;
  width: 26px;
  height: 26px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.woocommerce #yith-wcwl-form table.wishlist_table tr td,
.woocommerce #yith-wcwl-form table.wishlist_table tr th.wishlist-delete,
.woocommerce #yith-wcwl-form table.wishlist_table tr th.product-checkbox {
  text-align: inherit;
}
.woocommerce #yith-wcwl-form table.wishlist_table .product-name .yith-wcqv-button {
  color: #fff;
  margin-left: 5px;
}
.woocommerce #yith-wcwl-form table.wishlist_table .product-name .yith-wcqv-button:hover {
  color: #fff;
}
.woocommerce #yith-wcwl-form table.wishlist_table .product-add-to-cart {
  display: table-cell;
}
.woocommerce #yith-wcwl-form table.wishlist_table .product-add-to-cart a.add_to_cart {
  padding: 1.15em 2.1em;
  text-transform: none;
  letter-spacing: 0;
}
.woocommerce #yith-wcwl-form table.wishlist_table .product-add-to-cart .add_to_cart.button {
  border-radius: 0;
}

/* 1.4. Shop Checkout Page */
.woocommerce-checkout #content {
  padding-top: 70px;
  padding-bottom: 70px;
}
.woocommerce-checkout .titlebar + #content {
  padding-top: 40px;
}

.woocommerce form.woocommerce-checkout h3 {
  margin: 1.5em 0 0.7142857143em;
  font-size: 35px;
  line-height: 1.5em;
  font-weight: 700;
}
.woocommerce form.woocommerce-checkout .form-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-flex: 1;
          flex: 1 auto;
  -webkit-box-align: end;
          align-items: flex-end;
  margin-bottom: 13px;
}
.woocommerce form.woocommerce-checkout .form-row label,
.woocommerce form.woocommerce-checkout .form-row .woocommerce-input-wrapper {
  -webkit-box-flex: 1;
          flex: 1 0 100%;
}
.woocommerce form.woocommerce-checkout .form-row label {
  font-size: 15px;
  font-weight: 500;
}
.woocommerce form.woocommerce-checkout .form-row label .required {
  color: #D0021B;
}
.woocommerce form.woocommerce-checkout .form-row label.checkbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
  line-height: 1.5em;
}
.woocommerce form.woocommerce-checkout .form-row label.checkbox input {
  width: auto;
  height: auto;
  position: relative;
  margin: 0.35em 10px 0 0;
}
.woocommerce form.woocommerce-checkout .form-row .select2 {
  max-width: 100%;
}
.woocommerce form.woocommerce-checkout .form-row .select2-selection--single,
.woocommerce form.woocommerce-checkout .form-row .input-text,
.woocommerce form.woocommerce-checkout .form-row textarea {
  width: 100%;
  padding: 15px 25px;
  border: 1px solid #E1E1E1;
  border-radius: 0;
  background: none;
}
.woocommerce form.woocommerce-checkout .form-row .select2-selection--single:focus,
.woocommerce form.woocommerce-checkout .form-row .input-text:focus,
.woocommerce form.woocommerce-checkout .form-row textarea:focus {
  border-color: var(--color-primary);
}
.woocommerce form.woocommerce-checkout .form-row textarea.input-text {
  height: auto;
}
.woocommerce form.woocommerce-checkout .form-row .select2-selection--single,
.woocommerce form.woocommerce-checkout .form-row .input-text {
  display: -webkit-box;
  display: flex;
  height: 45px;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-box-align: center;
          align-items: center;
}
.woocommerce form.woocommerce-checkout .form-row .select2-container--open .select2-selection--single {
  border-color: transparent;
  background-color: #F9F9F9;
  color: #3C3C3C;
  border-radius: 5px 5px 0 0;
}
.woocommerce form.woocommerce-checkout .form-row.form-row-wide {
  width: 100%;
}
.woocommerce form.woocommerce-checkout .ui-selectmenu-button {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 45px;
  padding: 15px 25px;
  border: 1px solid #E1E1E1;
  border-radius: 0;
  background: none;
  -webkit-box-align: center;
          align-items: center;
}
.woocommerce form.woocommerce-checkout .ui-selectmenu-button .ui-selectmenu-icon {
  display: -webkit-inline-box;
  display: inline-flex;
  position: absolute;
  top: 50%;
  right: 25px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.woocommerce form.woocommerce-checkout .ui-selectmenu-button .ui-selectmenu-icon:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 5px 5px 0 5px;
  border-color: #cecece transparent transparent;
  border-style: solid;
}
.woocommerce form.woocommerce-checkout .woocommerce-billing-fields__field-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
}
.woocommerce form.woocommerce-checkout h3.order_review_heading {
  padding: 1.92em 40px 1.28em;
  margin-bottom: 0;
  position: relative;
  background-color: #F9F9F9;
  font-size: 25px;
}
.woocommerce form.woocommerce-checkout h3.order_review_heading:before {
  content: '';
  display: block;
  position: absolute;
  top: -14px;
  right: 0;
  left: 0;
  height: 14px;
  background: linear-gradient(-45deg, #f8f8f8 33.333%, transparent 33.333%, transparent 66.667%, #f8f8f8 66.667%), linear-gradient(45deg, #f8f8f8 33.333%, transparent 33.333%, transparent 66.667%, #f8f8f8 66.667%);
  background-size: 12px 40px;
  background-position: 0 -20px;
}
.woocommerce form.woocommerce-checkout #ship-to-different-address {
  cursor: pointer;
}
.woocommerce form.woocommerce-checkout #ship-to-different-address label {
  cursor: inherit;
}
.woocommerce form.woocommerce-checkout #ship-to-different-address span {
  display: block;
  position: relative;
  padding-left: 1.25em;
  line-height: 1.15em;
}
.woocommerce form.woocommerce-checkout #ship-to-different-address span:before, .woocommerce form.woocommerce-checkout #ship-to-different-address span:after {
  content: '';
  display: inline-block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -13px;
  border-radius: 3px;
  background-color: #efefef;
}
.woocommerce form.woocommerce-checkout #ship-to-different-address span:after {
  -webkit-transform: scale(0);
          transform: scale(0);
  border-radius: 10px;
  -webkit-transition: all 0.65s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.65s cubic-bezier(0.23, 1, 0.32, 1);
}
.woocommerce form.woocommerce-checkout #ship-to-different-address input[type=checkbox] {
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  visibility: hidden;
}
.woocommerce form.woocommerce-checkout #ship-to-different-address input[type=checkbox]:checked + span:after {
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  border-radius: 3px;
  background-color: currentColor;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table {
  width: 100%;
  padding: 0 40px 40px;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: #F9F9F9;
  font-size: 15px;
  color: #000;
  border-collapse: separate;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table th,
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table td {
  border: none;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table th {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tbody td {
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tbody .product-total {
  text-align: right;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot td,
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot th {
  padding-left: 0;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot td:last-child {
  text-align: right;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .cart-subtotal th,
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .cart-subtotal td {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #D4D4D4;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .cart-subtotal .woocommerce-Price-amount {
  font-size: 20px;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .order-total th,
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .order-total td {
  padding-top: 30px;
  border-top: 1px dashed #D4D4D4;
}
.woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .order-total .woocommerce-Price-amount {
  font-size: 30px;
}
.woocommerce form.woocommerce-checkout #payment {
  padding: 0 40px 30px;
  border-radius: 0;
  background-color: #F9F9F9;
}
.woocommerce form.woocommerce-checkout #payment div.form-row,
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods {
  padding: 0;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods {
  border: none;
  margin-bottom: 1.75em;
  list-style: none;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods li .lqd-woo-radio-input {
  margin-right: 18px;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods li .wc_payment_method_li_head {
  display: -webkit-box;
  display: flex;
  padding: 12px 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods li input {
  margin: 0;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods li label {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 700;
  color: #000;
  -webkit-box-flex: 1;
          flex: 1 auto;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods .woocommerce-SavedPaymentMethods-saveNew {
  -webkit-box-align: center;
          align-items: center;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods .woocommerce-SavedPaymentMethods-saveNew input {
  margin-right: 10px;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods .wc-credit-card-form label {
  -webkit-box-flex: 0;
          flex-grow: 0;
  margin-right: 5px;
}
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods .wc-credit-card-form .wc-stripe-elements-field,
.woocommerce form.woocommerce-checkout #payment ul.wc_payment_methods .wc-credit-card-form .stripe-card-group {
  -webkit-box-flex: 1;
          flex-grow: 1;
}
.woocommerce form.woocommerce-checkout #payment div.payment_box {
  padding: 0;
  margin: 1em 0;
  background: none;
  font-size: 15px;
  line-height: 1.7333333333em;
  color: #454545;
}
.woocommerce form.woocommerce-checkout #payment div.payment_box:before {
  content: none;
}
.woocommerce form.woocommerce-checkout #payment .place-order {
  border: none;
}
.woocommerce form.woocommerce-checkout #payment .place-order .woocommerce-privacy-policy-text {
  font-size: 13px;
  line-height: 1.6923076923em;
}
.woocommerce form.woocommerce-checkout #payment .place-order .woocommerce-privacy-policy-text a {
  color: var(--color-primary);
}
.woocommerce form.woocommerce-checkout #payment #place_order {
  float: none;
  width: 100%;
  margin-top: 1.5em;
  background-color: var(--color-primary);
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
}
.woocommerce form.checkout_coupon {
  padding: 20px;
  margin-bottom: 2em;
  margin-top: -1em;
  border: 1px dotted #D4D4D4;
  border-top: none;
  border-radius: 0;
}
.woocommerce form.checkout_coupon:before, .woocommerce form.checkout_coupon:after {
  content: '';
  display: table;
}
.woocommerce form.checkout_coupon:after {
  clear: both;
}
.woocommerce form.checkout_coupon p.form-row {
  width: auto !important;
  float: left !important;
  margin-right: 15px;
}
.woocommerce form.checkout_coupon input,
.woocommerce form.checkout_coupon button {
  height: 50px;
}
.woocommerce form.checkout_coupon input {
  min-width: 320px;
  padding: 1.05em 2.1em;
  border: 1px solid #E1E1E1;
  font-size: 13px;
}

/* 1.5. Shop Extra */
.woocommerce .woocommerce-info {
  background: none;
  border: 0;
  font-size: 13px;
  margin-bottom: 1em !important;
}
.woocommerce .woocommerce-info a:hover {
  text-decoration: underline;
}
.woocommerce .woocommerce-info:before {
  color: var(--color-primary);
}
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
  display: -webkit-box;
  display: flex;
  padding: 0.6em 0 0.6em 45px;
  border: 1px dotted #D4D4D4;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
  background-color: transparent;
  font-size: 15px;
  font-weight: 700;
}
.woocommerce .woocommerce-error:before,
.woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-message:before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.woocommerce .woocommerce-error a,
.woocommerce .woocommerce-info a,
.woocommerce .woocommerce-message a {
  color: var(--color-primary);
}
.woocommerce .woocommerce-error .button,
.woocommerce .woocommerce-info .button,
.woocommerce .woocommerce-message .button {
  margin-left: auto;
  color: #fff;
  -webkit-box-ordinal-group: 3;
          order: 2;
}
.woocommerce .woocommerce-error .button:hover,
.woocommerce .woocommerce-info .button:hover,
.woocommerce .woocommerce-message .button:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}
.woocommerce .woocommerce-form-coupon-toggle .woocommerce-info:before {
  content: '\f02b';
  font-family: fontAwesome;
}
.woocommerce .woocommerce-form-coupon-toggle .woocommerce-info a {
  margin-left: 0.5em;
}

.ld-shop-notice {
  padding: 15px;
  background-color: #FBED3F;
}
.ld-shop-notice h3 {
  margin: 0;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.11em;
  line-height: 1.5em;
}

.ld-shop-topbar {
  padding: 24px 0;
  margin-bottom: 50px;
  border-bottom: 1px solid #E9E9E9;
  color: #454545;
}
.ld-shop-topbar ul a {
  color: #454545;
}
.ld-shop-topbar ul span {
  opacity: 1;
  color: inherit;
}
.ld-shop-topbar ul a:hover {
  color: #000;
}
.woocommerce.single .ld-shop-topbar {
  margin-bottom: 0;
  border: none;
}
.contents-container .ld-shop-topbar {
  width: 100%;
  left: auto;
  right: auto;
  margin-left: 0;
  margin-right: 0;
}
.contents-container .ld-shop-topbar > .container {
  width: 100%;
  padding: 0;
}

.woocommerce-ordering .ui-selectmenu-button.ui-button {
  width: auto;
  border: none;
  background: none;
  color: #000;
  font-weight: 700;
}
.woocommerce-ordering .ui-selectmenu-button.ui-button .ui-selectmenu-text {
  margin-right: 30px;
}
.woocommerce-ordering .ui-selectmenu-button.ui-button .ui-icon {
  background: none;
}
.woocommerce-ordering .ui-selectmenu-button.ui-button .ui-icon:before {
  content: '\e977';
  display: -webkit-inline-box;
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-family: liquid-icon;
  color: var(--color-primary);
  text-indent: 0;
}

.pswp__bg {
  background-color: rgba(0, 0, 0, 0.75);
}

.lqd-woo-steps-number {
  display: -webkit-inline-box;
  display: inline-flex;
  width: 24px;
  height: 24px;
  margin-right: 2.2727272727em;
  border-radius: 50em;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  flex-shrink: 0;
}

.lqd-woo-steps-item {
  display: -webkit-inline-box;
  display: inline-flex;
  padding: 36px 30px;
  position: relative;
  -webkit-box-flex: 1;
          flex: 1 auto;
  -webkit-box-align: center;
          align-items: center;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 700;
  color: #333437;
}
.lqd-woo-steps-item.is-active .lqd-woo-steps-number {
  background-color: var(--color-primary);
}
.lqd-woo-steps-item svg {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.lqd-woo-steps-inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 0 30px;
}

.lqd-woo-steps {
  border: 1px solid #EFEFEF;
  margin-bottom: 2em;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0;
}
.select2-container--default .select2-selection--single:focus {
  outline: none;
}
.select2-container--default .select2-container--open .select2-selection--single {
  border-color: transparent;
  background-color: #F9F9F9;
  color: #3C3C3C;
  border-radius: 5px 5px 0 0;
}

.select2-dropdown {
  padding: 15px 0 0;
  border: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);
  background-color: #F9F9F9;
}

.select2-results__option {
  margin: 0 15px;
  padding: 7px 15px;
  font-size: 14px;
  line-height: 1.5em;
  border-radius: 5px;
  outline: none !important;
}
.select2-results__option.select2-results__option--highlighted[aria-selected], .select2-results__option.select2-results__option--highlighted[data-selected] {
  background-color: var(--color-primary);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
}

.select2-search {
  margin: 0 15px;
  padding: 0;
  border: none;
}
.select2-search .select2-search__field {
  border: none !important;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.select2-results {
  padding: 18px 0;
}

.lqd-woo-radio-input {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
}
.lqd-woo-radio-input span {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: 1px solid #B1B1B1;
  border-radius: 50em;
  position: relative;
}
.lqd-woo-radio-input span:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -5px;
  border-radius: inherit;
  background-color: var(--color-primary);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.3s;
  transition: opacity 0.15s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.15s;
  transition: transform 0.3s, opacity 0.15s, -webkit-transform 0.3s;
  opacity: 0;
  -webkit-transform: scale(2.2);
          transform: scale(2.2);
}
.lqd-woo-radio-input input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
}
.lqd-woo-radio-input input:checked + span {
  border-color: var(--color-primary);
}
.lqd-woo-radio-input input:checked + span:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.woocommerce-form-login input[type=text],
.woocommerce-form-login input[type=password] {
  border: 1px solid #ededed;
  height: 45px;
  padding: 5px 1em;
}
.woocommerce-form-login .woocommerce-form__label-for-checkbox {
  margin-left: 0.5em;
}

#yith-wcwl-popup-message {
  padding: 0.5em 1.5em;
  margin: 0 !important;
  border: none;
  border-radius: 3px;
  top: auto;
  bottom: 30px;
  left: 30px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  background-color: #333;
  line-height: 1.5em;
  color: #fff;
}

/*
  2. SHOP RESPONSIVE
*/
/* 2.1. Shop Product */
@media screen and (max-width: 767px) {
  .woocommerce ul.products {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .woocommerce ul.products li.product {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (max-width: 991px) {
  .woocommerce .ld-sp .ld-sp-add-to-cart,
  .woocommerce .ld-sp .add_to_cart_button {
    margin-top: 0 !important;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
/* 2.2. Shop Single Page */
@media screen and (min-width: 992px) {
  .product {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .product:before, .product:after {
    content: none;
  }
}
@media screen and (min-width: 992px) {
  .woocommerce div.product div.summary {
    width: 48%;
    margin-left: auto;
  }
}
@media screen and (max-width: 991px) {
  .woocommerce div.product div.summary {
    width: 100%;
  }
  .woocommerce div.product div.summary + .product-meta {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .woocommerce div.product div.images {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    float: none !important;
    width: 100% !important;
  }
  .woocommerce div.product div.images .carousel-container {
    width: 100%;
  }
  .woocommerce div.product div.images .carousel-container .row {
    margin: 0;
  }
  .woocommerce div.product div.images .ld-product-images-thumb-carousel .row {
    margin: 0 -5px;
  }
  .woocommerce div.product div.images .ld-product-images-thumb-carousel .carousel-item {
    padding-left: 5px;
    padding-right: 5px;
  }
  .woocommerce div.product div.images .ld-product-images-thumb-carousel figure {
    margin-top: 2px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: box-shadow 0.3s, -webkit-transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s;
    transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
  }
  .woocommerce div.product div.images .ld-product-images-thumb-carousel .is-nav-selected figure {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 0 5px 7px -2px rgba(0, 0, 0, 0.15);
  }
  .woocommerce div.product .ld-product-images {
    width: 48%;
    margin-right: auto;
  }
}
@media screen and (max-width: 991px) {
  .woocommerce #content div.product div.images {
    width: 100%;
    margin-bottom: 45px;
  }
}
@media screen and (min-width: 991px) {
  .woocommerce div.product form.cart.variations_form + .yith-wcwl-add-to-wishlist {
    margin-top: -60px;
    margin-left: 15px;
  }
  .woocommerce div.product form.cart.variations_form + .yith-wcwl-add-to-wishlist,
  .woocommerce div.product form.cart.variations_form + .yith-wcwl-add-to-wishlist + .social-icon {
    float: right;
  }
  .woocommerce div.product form.cart.variations_form + .yith-wcwl-add-to-wishlist + .social-icon {
    margin-top: -42px;
  }
  .woocommerce div.product form.cart.variations_form + .social-icon {
    float: right;
    margin-top: -42px;
  }
  .woocommerce div.product form.cart.variations_form ~ .product_meta {
    clear: both;
  }
}
@media screen and (min-width: 992px) {
  .woocommerce div.product .woocommerce-tabs .tabs-nav {
    width: 27%;
  }
  .woocommerce div.product .woocommerce-tabs .tabs-content {
    width: 73%;
    padding-left: 60px;
  }
}
@media screen and (max-width: 991px) {
  .woocommerce div.product .woocommerce-tabs {
    padding-left: 0;
    padding-right: 0;
  }
  .woocommerce div.product .woocommerce-tabs .tabs-nav {
    width: 100%;
    margin-bottom: 1.5em;
  }
}
@media screen and (min-width: 992px) {
  .woocommerce #review_form #respond .comment-form .comment-form-author,
  .woocommerce #review_form #respond .comment-form .comment-form-email {
    width: calc(50% - 20px);
  }
  .woocommerce #review_form #respond .comment-form .comment-form-email {
    margin-left: 40px;
  }
}
@media screen and (max-width: 767px) {
  .woocommerce #review_form #respond {
    padding: 30px 20px;
  }
}
/* 2.3. Shop Cart Page */
@media screen and (max-width: 767px) {
  .woocommerce #yith-wcwl-form table.shop_table th,
  .woocommerce #yith-wcwl-form table.shop_table td,
  .woocommerce .woocommerce-cart-form table.shop_table th,
  .woocommerce .woocommerce-cart-form table.shop_table td {
    padding-left: 15px;
    padding-right: 15px;
  }
  .woocommerce .woocommerce-cart-form table.shop_table tr {
    position: relative;
  }
  .woocommerce .woocommerce-cart-form table.shop_table td.product-name {
    padding-left: 15px;
  }
  .woocommerce .woocommerce-cart-form table.shop_table td.product-name .image {
    margin-top: 15px;
  }
  .woocommerce .woocommerce-cart-form table.shop_table td.cart-coupon:before {
    content: none;
  }
  .woocommerce .woocommerce-cart-form table.shop_table td.actions {
    padding-left: 0;
    padding-right: 0;
  }
  .woocommerce .woocommerce-cart-form table.shop_table td.actions .continue_shopping {
    margin-bottom: 10px;
    text-align: center;
  }
  .woocommerce .woocommerce-cart-form table.shop_table td.actions .update_cart {
    margin-left: 0 !important;
  }
  .woocommerce .woocommerce-cart-form table.shop_table td.product-remove {
    padding: 0;
    background: none;
    position: absolute;
    top: 0.5em;
    right: 15px;
    font-size: 24px;
  }
  .woocommerce .woocommerce-cart-form table.shop_table tr.shipping th,
  .woocommerce .woocommerce-cart-form table.shop_table tr.shipping .ui-selectmenu-button {
    width: 100%;
  }
  .woocommerce .woocommerce-cart-form table.shop_table tr.shipping th {
    display: block;
  }
  .woocommerce .woocommerce-cart-form table thead {
    display: none;
  }
  .woocommerce .woocommerce-cart-form table tbody tr:first-child td:first-child {
    border-top: 0;
  }
  .woocommerce .woocommerce-cart-form table tbody th {
    display: none;
  }
  .woocommerce .woocommerce-cart-form table tr {
    display: block;
  }
  .woocommerce .woocommerce-cart-form table tr td {
    display: block;
    text-align: right !important;
  }
  .woocommerce .woocommerce-cart-form table tr td.order-actions {
    text-align: left !important;
  }
  .woocommerce .woocommerce-cart-form table tr td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }
  .woocommerce .woocommerce-cart-form table tr td.actions::before,
  .woocommerce .woocommerce-cart-form table tr td.product-remove::before {
    display: none;
  }
  .woocommerce .woocommerce-cart-form table tr:nth-child(2n) td {
    background-color: rgba(0, 0, 0, 0.025);
  }
  .woocommerce .woocommerce-cart-form .cart-collaterals .cart_totals,
  .woocommerce .woocommerce-cart-form .cart-collaterals .cross-sells,
  .woocommerce .woocommerce-cart-form .cart-collaterals .shipping_calculator {
    width: 100%;
    float: none;
    text-align: left;
  }
}
/* 2.4. Shop Checkout Page */
@media screen and (max-width: 479px) {
  .woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tr {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tbody td {
    border: none;
  }
  .woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .cart-subtotal th,
  .woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .cart-subtotal td,
  .woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .order-total th,
  .woocommerce form.woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot .order-total td {
    border: none;
  }
}
/* 2.5. Shop Extra */
@media screen and (min-width: 992px) {
  .contents-container .ld-shop-topbar > .container > .row > div:first-child {
    width: 33.33333333%;
  }
  .contents-container .ld-shop-topbar > .container > .row > div:last-child {
    width: 66.66666667%;
  }
}
@media screen and (min-width: 992px) {
  .lqd-woo-steps-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
  }

  .lqd-woo-steps-item {
    flex-basis: 33.3333333333%;
  }
}
@media screen and (max-width: 1199px) {
  .lqd-woo-steps-inner {
    padding: 0;
    text-align: center;
  }

  .lqd-woo-steps-item {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (max-width: 991px) {
  .lqd-woo-steps-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }

  .lqd-woo-steps-item svg {
    right: auto;
    top: auto;
    left: 50%;
    bottom: -30px;
    -webkit-transform: rotate(90deg) translateX(-50%);
            transform: rotate(90deg) translateX(-50%);
  }
}
@media screen and (min-width: 992px) {
  .woocommerce.has-sidebar .sidebar-container.col-md-offset-1 {
    margin-left: 2.33333333%;
  }
  .woocommerce.has-sidebar .contents-container {
    width: 72.66666667%;
  }
  .woocommerce.has-sidebar .contents-container.col-md-offset-1 {
    margin-left: 2.33333333%;
  }
}