/*buttons questions component*/

.button-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 200px;
align-items: center;
}

.button-flex {
    width: 60%;
    min-width: 210px;
    height: 30%;
    background-color: rgba(90, 90, 90, 1);
    color: white;
    border-radius: 8px;
    z-index: 1;
    transition: 1000ms ease-out;
    border: none;



}

.button-flex:hover
{
    cursor: grab;
    background-color: white;
    color: black;
    width: 70%;
    height: 35%;
}
.active{
    cursor: grab;
    background-color: white;
    color: black;
    width: 70%;
    height: 35%;
    border-radius: 8px;
    box-shadow: none;
    outline: none
}

/*AVIAREPS Colors*/
/*background-colors*/
.bg-turkies {
    background-color: #23a8ba !important;
}

.bg-dark {
    background-color: #000f40;
}

bg-grey{
    background-color: rgb(90, 90, 90);
}


/*form style configurator*/

.form-button{
    width: 30%;
    height: 50px;
    background-color: rgba(90, 90, 90, 1);
    color: white;
    border-radius: 8px;
    z-index: 1;
    border: none;
    margin: 0px 0px 0px 10px
}

.form-button-select{
    width: 55%;
    height: 50px;
    background-color: rgba(90, 90, 90, 1);
    color: white;
    border-radius: 8px;
    z-index: 1;
    border: none;
    margin: 0px 0px 0px 10px;
}

.form-button::placeholder{
    color: white;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: center;
}
.form-box{
    display: flex;
    flex-direction:row;
    width: 100%;
    height: 90px;
    float: left;
    justify-content: space-around;

}

/*Counter*/

.counter{
    position: fixed;
    top: 15px;
    z-index: 3;
    width: 100%
}

.counter-item{
    background-color: white;
    width: 4em;
    color: #23a8ba;
    text-align: center;
    margin: auto;
    border-radius: 8px;
}

/*liquid progressbar design*/

.bg-progressbar-turkies-50 {
    background: linear-gradient(90deg, #23a8ba 50%, white 50%);
}

.bg-progressbar-turkies-50:hover {
    background: linear-gradient(90deg, #23a8ba 70%, white 30%)
}

.bg-progressbar-turkies-70 {
    background: linear-gradient(90deg, #23a8ba 70%, white 30%)
}

.bg-progressbar-turkies-70:hover {
    background: linear-gradient(90deg, #23a8ba 85%, white 15%)
}
.bg-progressbar-turkies-90 {
    background: linear-gradient(90deg, #23a8ba 90%, white 10%)
}
.bg-progressbar-turkies-90:hover {
    background: linear-gradient(90deg, #23a8ba 100%, white 0%)
}